<template>
  <button @click="speak">SPEAK</button>
</template>

<script>
import {
  SpeechConfig,
  AudioConfig,
  SpeechSynthesizer,
} from "microsoft-cognitiveservices-speech-sdk";

export default {
  name: "TestTextToSpeech",
  components: {},
  props: {
    //content: Object,
  },
  data() {
    return {
      txt: "Serviamo il cliente numero X-775",
    };
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
  computed: {
    //
  },
  methods: {
    speak: function() {
      const speechConfig = SpeechConfig.fromSubscription(
        "639dbe741c95458480cc52066a9832e5",
        "westeurope"
      );
      speechConfig.speechSynthesisLanguage = "it-IT";
      speechConfig.speechSynthesisVoiceName = "it-IT-ElsaNeural";

      const audioConfig = AudioConfig.fromDefaultSpeakerOutput();

      // eslint-disable-next-line no-undef
      const synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);
      synthesizer.speakTextAsync(
        this.txt,
        (result) => {
          if (result) {
            synthesizer.close();
            return result.audioData;
          }
        },
        (error) => {
          console.log(error);
          synthesizer.close();
        }
      );
    },
  },
};
</script>

<style></style>
